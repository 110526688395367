const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Icons = require('./icons');

function InfoSlide({
  title_label: titleLabel,
  picture,
  description,
  disclaimer,
  target_url: targetUrl,
  link_label: linkLabel,
  tabIndex,
}) {
  const IconImage = Icons[picture];

  const infoSlideTitleClass = classNames('info-slide__title', {
    'info-slide__disclaimer': disclaimer,
  });

  return (
    <div key={titleLabel} className="info-slide" tabIndex={tabIndex}>
      <div className="img-container">
        <IconImage />
      </div>
      <h2 className={infoSlideTitleClass}>{titleLabel}</h2>
      <p>{description}</p>
      { disclaimer && (
        <p>
          <small className="info-slide__shipping-conditions">{disclaimer}</small>
        </p>
      )}
      { targetUrl && <a href={targetUrl}>{linkLabel}</a> }
    </div>
  );
}

InfoSlide.propTypes = {
  description: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  link_label: PropTypes.string,
  picture: PropTypes.string.isRequired,
  target_url: PropTypes.string,
  title_label: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
};

InfoSlide.defaultProps = {
  target_url: null,
  link_label: null,
  disclaimer: null,
  tabIndex: -1,
};

module.exports = InfoSlide;

const React = require('react');
const PropTypes = require('prop-types');

const RenderTree = require('@mshops-components-library/render-tree').default;

const DisabledWrapper = require('../../components/disabled-wrapper');

const { useRenderContext } = require('../../pages/home/context');

const RenderTreeContainer = ({ componentsList, ...props }) => {
  const { appearance, device } = useRenderContext();
  return (<RenderTree
    {...props}
    deviceType={device}
    node={appearance.getTree()}
    componentsList={componentsList}
    DisabledWrapper={DisabledWrapper}
  />);
};

RenderTreeContainer.propTypes = {
  componentsList: PropTypes.shape({}).isRequired,
};

module.exports = RenderTreeContainer;

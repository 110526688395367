const React = require('react');
const PropTypes = require('prop-types');

const Clickable = require('@mshops-web-components/clickable').default;

const metadataPropType = require('../../../../components/metadataProvider/metadataPropType');
const wordings = require('./wordings');

class MoreInfoMessage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { metadata } = this.context;
    const { shopModel } = metadata;
    const { i18n } = this.props;
    const locales = wordings(i18n);

    window.location.assign(`${shopModel.shop.home_url}/p/${locales.contact}`);
  }

  render() {
    const { i18n } = this.props;
    const locales = wordings(i18n);
    return (
      <div className="how-buy-landing__more-info-message">
        <p>
          {locales.text}
          <Clickable onClick={this.handleClick}>
            {locales.link}
          </Clickable>
        </p>
      </div>
    );
  }
}

MoreInfoMessage.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

MoreInfoMessage.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = MoreInfoMessage;

const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');

const CarouselSnapped = require('@andes/carousel-snapped');

const Badge = require('./badge').default;
const { MAX_ITEMS_PER_VIEW_BY_DEVICE, SLIDE_THRESHOLD_BY_DEVICE, buildImageUrl } = require('./config');

const { CarouselSnappedSlide } = CarouselSnapped;

const BadgeCarousel = ({ componentId, title, shape, variations, nodeItems, deviceType }) => {
  if (nodeItems.length === 0) {
    return null;
  }

  // hub_carousel class is only added to maintain compatibility with custom html shops
  const articleClases = ClassNames('hub__carousel hub_carousel');

  const carouselClasses = ClassNames(
    variations === 'brands' ? 'carousel__brands-ul' : 'carousel__ul',
    variations === 'brands' ? `carousel__brands-ul--${shape}` : `carousel__ul--${shape}`,
  );

  const slides = nodeItems.map(({ id, properties }, position) => ({
    id,
    label: properties.label,
    image: buildImageUrl(properties.image_src),
    link: properties.link,
    position,
  }));

  const maxSlidesPerView = MAX_ITEMS_PER_VIEW_BY_DEVICE[deviceType] || 6;
  const slideThreshold = SLIDE_THRESHOLD_BY_DEVICE[deviceType] || 0;

  const settings = {
    srLabel: title,
    speed: 150,
    slidesPerView: (slides.length >= maxSlidesPerView) ? maxSlidesPerView : slides.length,
    pagination: false,
    lazyLoad: true,
    type: 'full',
    arrows: (deviceType !== 'mobile'),
    thresholdSlide: (slides.length > maxSlidesPerView) ? slideThreshold : 0,
  };

  return (
    <article className={articleClases}>
      <h2 className="title">{title}</h2>
      <div className={carouselClasses}>
        <CarouselSnapped {...settings}>
          { slides.map(slide => (
            <CarouselSnappedSlide key={`${componentId}-badge-${slide.label}`}>
              <Badge {...slide} deviceType={deviceType} />
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      </div>
    </article>
  );
};

BadgeCarousel.propTypes = {
  componentId: PropTypes.string.isRequired,
  title: PropTypes.string,
  shape: PropTypes.oneOf(['circle', 'square']),
  variations: PropTypes.oneOf(['categories', 'brands']).isRequired,
  nodeItems: PropTypes.arrayOf(PropTypes.shape({
    props: PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        properties: PropTypes.shape(),
      }),
    }),
  })),
  deviceType: PropTypes.string,
};

BadgeCarousel.defaultProps = {
  title: null,
  shape: 'circle',
  nodeItems: [],
  deviceType: 'desktop',
};

module.exports = BadgeCarousel;

const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Chevron = require('../icons/Chevron');

class FaqItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { active: false };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      active: !this.state.active,
    });
  }

  render() {
    const { title, text } = this.props;

    const chevronClasses = classNames('faqs__item-icon', { 'faqs__item-icon--disabled': !this.state.active });
    const textClasses = classNames('faqs__item-text', { 'faqs__item-text--disabled': !this.state.active });

    return (
      <li className="faqs__item">
        <div className="faqs__item-content" aria-label={title} onClick={this.handleClick} role="button" tabIndex={-1}>
          <div className="faqs__item-title-button">
            <h3 className="faqs__item-title">{title}</h3>
            <div className={chevronClasses}>
              <Chevron />
            </div>
          </div>
          <h4 className={textClasses}>{text}</h4>
        </div>
      </li>
    );
  }
}

FaqItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

module.exports = FaqItem;

module.exports = (i18n) => ({
  placeholder: i18n.gettext(''),
  addComponentTooltip: i18n.gettext('Agregar nuevo componente'),
  moveUpWording: i18n.gettext('Subir sección'),
  moveDownWording: i18n.gettext('Bajar sección'),
  deleteWording: i18n.gettext('Eliminar sección'),
  tableWordings: {
    modal: {
      title: i18n.gettext('Agrega una tabla de texto'),
      rows: {
        label: i18n.gettext('Filas'),
        helper: i18n.gettext('Máximo 10 filas.'),
        limit: 10,
      },
      columns: {
        label: i18n.gettext('Columnas'),
        helper: i18n.gettext('Máximo 10 columnas.'),
        limit: 10,
      },
      actions: {
        primary: i18n.gettext('Agregar'),
        secondary: i18n.gettext('Cancelar'),
      },
      limitError: i18n.gettext('El máximo permitido es 10.'),
    },
  },
  modalLocales: {
    add_link: i18n.gettext('Agregar link'),
    cancel: i18n.gettext('Cancelar'),
    linkFieldError: i18n.gettext('Ingresa un link válido.'),
    linkFieldHelper: i18n.gettext('El link debe empezar con https:// o http://'),
    linkFieldPlaceholder: i18n.gettext('https://www.mitienda.com/productos'),
  },
  tooltips: {
    fontSize: i18n.gettext('Tamaño de fuente'),
    heading: i18n.gettext('Estilo'),
    align: i18n.gettext('Alineación del texto'),
    bold: i18n.gettext('Negrita'),
    italic: i18n.gettext('Cursiva'),
    underline: i18n.gettext('Subrayado'),
    table: i18n.gettext('Tabla'),
    unorderedList: i18n.gettext('Lista con viñetas'),
    orderedList: i18n.gettext('Lista numerada'),
    link: i18n.gettext('Link'),
    alignOptions: {
      left: i18n.gettext('Izquierda'),
      center: i18n.gettext('Al Centro'),
      right: i18n.gettext('Derecha'),
      justify: i18n.gettext('Justificar'),
    },
  },
  headingOptions: [
    {
      title: i18n.gettext('Título'),
      value: 'h2',
      class: 'title',
    },
    {
      title: i18n.gettext('Subtítulo'),
      value: 'h3',
      class: 'subtitle',
    },
    {
      title: `${i18n.gettext('Párrafo')} 1`,
      value: 'h4',
      class: 'major-paragraph',
    },
    {
      title: `${i18n.gettext('Párrafo')} 2`,
      value: 'p',
      class: 'minor-paragraph',
    },
  ],
});

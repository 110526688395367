const React = require('react');
const PropTypes = require('prop-types');

const { Button } = require('@andes/button');

const { RecommendationsContainer } = require('@mshops-components-library/recommendations');
const { useRenderContext } = require('../../pages/home/context');


const Mosaic = (props) => {
  const { children, seeMoreAction } = props;
  if ((children.length > 0) && (!children[0].props.node.children.length)) {
    return null;
  }

  const { device } = useRenderContext();

  return (
    <RecommendationsContainer {...props} deviceType={device}>
      {children}
      {seeMoreAction
        && (
        <Button
          className="grid-recommendations-button"
          href={seeMoreAction.link}
        >
          {seeMoreAction.label}
        </Button>
        )}
    </RecommendationsContainer>
  );
};

Mosaic.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.element,
  ),
  seeMoreAction: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
};

Mosaic.defaultProps = {
  seeMoreAction: null,
  children: [],
};

module.exports = Mosaic;

const React = require('react');
const PropTypes = require('prop-types');

const wordings = require('./wordings');

const Title = ({ i18n, flowGuest, shopName }) => {
  const locales = wordings(i18n, shopName);

  return (
    <div className="how-buy-landing__title-section">
      <h2 className="title-section__title">{locales.title}</h2>
      <p className="title-section__text">
        { flowGuest ? locales.main.first
          : (
            <>
              { locales.noGuest.first }
              <strong>{ shopName }</strong>
              { locales.noGuest.second }
            </>
          )
        }
      </p>
    </div>
  );
};

Title.propTypes = {
  i18n: PropTypes.shape({
    gettest: PropTypes.func,
  }).isRequired,
  flowGuest: PropTypes.bool,
  shopName: PropTypes.string,
};

module.exports = Title;

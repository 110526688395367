const React = require('react');
const PropTypes = require('prop-types');

const Style = require('nordic/style');

const Action = require('../action');
const Section = require('../section');

function Container({ children, className, components, isMobile }) {
  const Elem = isMobile ? 'a' : 'div';
  return (
    <Elem
      href={isMobile ? components.find(c => c.view_more).view_more.target : null}
      className={className}
    >
      {children}
    </Elem>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  components: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

function PaymentData(props) {
  if (!props.components.length) {
    return null;
  }

  const isMobile = props.deviceType === 'mobile';
  const components = [[]];
  let lastComponent = props.components[0];
  props.components.forEach((component) => {
    component.id = `${component.type}-${component.title}`;
    if (lastComponent.type !== component.type) {
      components.push([]);
    }
    components[components.length - 1].push(component);
    const isPromoComponent = component.type === 'promo';

    if (isPromoComponent) {
      props.promos.sort((a, b) => b.max_installments - a.max_installments).forEach((promo) => {
        components[components.length - 1].push({
          id: `promo-${promo.issuer.id}`,
          type: 'promo-logo',
          target: component.view_more.target,
          modal_title: component.view_more.modal_title,
          ...promo,
        });
      });
    }
    lastComponent = component;
  });
  return (
    <Section type={`${props.type} ${props.siteId}`}>
      <Style renderChildrenInPlace={false}>{Object.values(props.paymentStyles).join(' ')}</Style>

      <div className="container">
        <Container {...props} className="payment-data-container" isMobile={isMobile}>
          {components.map(componentList => (
            <div key={componentList[0].id} className={`payment-data-group payment-data-group-${componentList[0].type}`}>
              {componentList.map(component => (
                <div key={component.id} className={`payment-data-section payment-data-section-${component.type}`}>
                  {component.icon_url
                    && (isMobile ? (
                      <div className="payment-data-icon">
                        <img alt="" src={component.icon_url} />
                      </div>
                    ) : (
                      <a
                        className="payment-data-icon"
                        href={component.view_more && component.view_more.target}
                        onClick={props.openModal}
                        data-title={component.view_more && component.view_more.modal_title}
                      >
                        <img alt="" src={component.icon_url} />
                      </a>
                    ))}
                  {component.title && <div className="payment-data-title" dangerouslySetInnerHTML={{ __html: component.title }} />}
                  {component.subtitle && <div className="payment-data-subtitle">{component.subtitle}</div>}
                  {component.view_more
                    && component.view_more.label && (
                      <div className="payment-data-subtitle">
                        {isMobile ? (
                          <div className="ui-link">{component.view_more.label}</div>
                        ) : (
                          <Action
                            {...component.view_more}
                            data-title={component.view_more.modal_title}
                            onClick={props.openModal}
                          />
                        )}
                      </div>
                  )}
                  {component.issuer && (
                    <div className="payment-data-issuer">
                      {isMobile ? (
                        <div className={`payments-${props.logosSize} _${component.issuer.id}-${props.logosSize}`} />
                      ) : (
                        <a
                          href={`${component.target}#_${component.issuer.id}`}
                          onClick={props.openModal}
                          data-title={component.modal_title}
                        >
                          <div className={`payments-${props.logosSize} _${component.issuer.id}-${props.logosSize}`} />
                        </a>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </Container>
      </div>

      {props.children}
    </Section>
  );
}

PaymentData.propTypes = {
  children: PropTypes.node,
  components: PropTypes.array.isRequired,
  deviceType: PropTypes.string.isRequired,
  logosSize: PropTypes.string.isRequired,
  openModal: PropTypes.func,
  paymentStyles: PropTypes.shape({
    sprite_css: PropTypes.string,
    picture_css: PropTypes.string,
  }).isRequired,
  promos: PropTypes.array,
  siteId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

PaymentData.defaultProps = {
  children: null,
  openModal: null,
  promos: [],
};

module.exports = PaymentData;

import React from 'react';
import PropTypes from 'prop-types';

import metadataPropType from '../../components/metadataProvider/metadataPropType';

const ProductsCarouselWrapper = (RecommendationsBase) => {
  const ProductsCarouselBase = (props, context) => {
    const { metadata } = context;
    const { currency, restClient, layoutTheme } = metadata;
    const { i18n } = props;
    const shippingTitle = i18n.gettext('Envío gratis');

    return (props.tabs.length > 0)
      && (
      <div className="mshops-recommendations-wrapper">
        <RecommendationsBase
          {...props}
          metadata={currency}
          restClient={restClient}
          type="recommendations"
          shippingTitle={shippingTitle}
          layoutTheme={layoutTheme}
        />
      </div>
      );
  };

  ProductsCarouselBase.propTypes = {
    i18n: PropTypes.shape({ gettext: PropTypes.func }).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        permalink: PropTypes.string.isRequired,
        picture: PropTypes.object.isRequired,
        price: PropTypes.object.isRequired,
        shipping: PropTypes.object.isRequired,
      })),
    })).isRequired,
  };

  ProductsCarouselBase.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return ProductsCarouselBase;
};

export default ProductsCarouselWrapper;

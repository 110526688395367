const React = require('react');
const PropTypes = require('prop-types');

const Title = require('./components/Title');
const Steps = require('./components/Steps');
const Faq = require('./components/Faq');
const MoreInfoMessage = require('./components/MoreInfoMessage');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const HowBuyInfo = ({ i18n }, context) => {
  const { shop } = context?.metadata?.shopModel;
  const featureToggle = shop?.features?.includes('mshops-guest-cho');
  return (
    <div className="how-buy-landing">
      <Title i18n={i18n} flowGuest={featureToggle} shopName={shop?.name} />
      <Steps i18n={i18n} flowGuest={featureToggle} />
      <Faq i18n={i18n} flowGuest={featureToggle} />
      <MoreInfoMessage i18n={i18n} />
    </div>
  );
};

HowBuyInfo.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsxgettext: PropTypes.func,
  }).isRequired,
};

HowBuyInfo.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = HowBuyInfo;

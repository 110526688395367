const React = require('react');
const PropTypes = require('prop-types');

const wordings = require('./wordings');
const Icons = require('./icons');

const Steps = ({ i18n, flowGuest }) => {
  const locales = wordings(i18n);
  const steps = flowGuest ? locales.steps.main : locales.steps.noGuest;

  return (
    <div className="how-buy-landing__steps">
      {
        steps.map(({ icon, title, text }) => {
          const Icon = Icons[icon];
          return (
            <div key={title}>
              <div className="steps__separator" />
              <div className="steps__step">
                <Icon />
                <h2 className="step__title">{title}</h2>
                <p className="step__text">{text}</p>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

Steps.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  flowGuest: PropTypes.bool,
};

module.exports = Steps;

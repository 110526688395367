const React = require('react');
const PropTypes = require('prop-types');
const Image = require('nordic/image');

const NotFoundPage = ({ icon, link, title, subtitle }) => (
  <div className="not-found-page">
    <Image className="not-found-page__img" src={icon} alt="not-found" />
    <div className="not-found-page__texts">
      <h1 className="not-found-page__title">{title}</h1>
      <h2 className="not-found-page__subtitle">{subtitle}</h2>
      <a className="not-found-page__link" href={link.href}>{link.text}</a>
    </div>
  </div>
);

NotFoundPage.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

module.exports = NotFoundPage;

const React = require('react');
const PropTypes = require('prop-types');

const NewsletterModal = require('@mshops-web-components/newsletter-modal').default;
const ClientService = require('../../../services/client');

const wordings = require('./wordings');
const metadataPropType = require('../metadataProvider/metadataPropType');
const newsletterFormPropTypes = require('./utils/formProps');
const { trackEvent } = require('../../utils/tracking');
const tracks = require('../../utils/tracks');

const {
  NEWSLETTER_SUBSCRIPTION_COOKIE,
  NEWSLETTER_SUBSCRIPTION_EXPIRATION,
} = require('../../utils/constants/newsletterModal');

const { useState, useEffect } = React;

const NewsletterModalWrapper = (props, context) => {
  const locales = wordings(props.i18n);
  const { is_open: isOpen } = props.form;
  if (!isOpen) {
    return null;
  }

  const { deviceType, hideModalSubscription, isHubPreview, restClient, shopModel } = context.metadata;

  const { domain, id, name } = shopModel.shop;
  const commonTrack = {
    shop_id: id,
    shop_name: name,
    shop_domain: domain,
  };

  const [showCongrats, setShowCongrats] = useState(false);
  const [showModal, setShowModal] = useState(!hideModalSubscription);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const closeModal = () => {
    if (!showCongrats) {
      // Convert to seconds because when cookie is set on server side, express expects max-age to be in milliseconds
      const expirationInSeconds = NEWSLETTER_SUBSCRIPTION_EXPIRATION / 1000;
      document.cookie = `${NEWSLETTER_SUBSCRIPTION_COOKIE}=true; max-age=${expirationInSeconds}; path=/`;
    }

    setShowModal(false);
  };

  const handleSubscription = async (email) => {
    const request = new ClientService(restClient.baseURL);
    setIsFetching(true);
    trackEvent(tracks.newsletterSubscription, commonTrack);
    try {
      await request.post('/automations/subscribe-newsletter', { data: { email } });
      trackEvent(tracks.newsletterCongratsView, commonTrack);
      setShowCongrats(true);
    } catch (err) {
      const errorLabel = locales[err?.response?.data?.messageCode] || locales.GeneralError;
      setErrorMessage(errorLabel);
    }
    setIsFetching(false);
  };

  if (isHubPreview) {
    return null;
  }

  useEffect(() => {
    if (showModal) {
      trackEvent(tracks.newsletterView, commonTrack);
    }
  }, []);

  return (
    <NewsletterModal
      {...props}
      deviceType={deviceType}
      isOpen={showModal}
      handleSubscription={handleSubscription}
      isCongrats={showCongrats}
      closeModal={closeModal}
      isFetching={isFetching}
      errorMessage={errorMessage}
    />
  );
};

NewsletterModalWrapper.propTypes = {
  form: PropTypes.shape({
    ...newsletterFormPropTypes,
  }),
  images: PropTypes.shape({
    desktop: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
  campaign_id: PropTypes.number.isRequired,
};

NewsletterModalWrapper.contextTypes = {
  metadata: metadataPropType,
};

module.exports = NewsletterModalWrapper;

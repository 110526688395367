const Appearance = require('@mshops-web-core/appearance').default;

const renderDefaultState = {
  theme: 'simple',
  fiscalData: null,
  socialNetworks: [],
  contact: null,
  appearance: new Appearance({}),
  initial: new Appearance({}),
  fetching: false,
  apiBasePath: '/api',
  device: 'desktop',
  draftMode: false,
  editingComponent: null,
  action: '',
  sender: '',
  marketplaceInfo: {},
  osName: '',
  uuidVersion: null,
  isEshops: false,
  updateDevice: false,
  richTextValueSetted: '',
};

module.exports = renderDefaultState;

const React = require('react');
const PropTypes = require('prop-types');

const CarouselSnapped = require('@andes/carousel-snapped');

const InfoSlide = require('./info-slide');
const Section = require('../section');

const { CarouselSnappedSlide } = CarouselSnapped;

const carouselProps = {
  arrows: false,
  dots: true,
  speed: 300,
  type: 'full',
};

function SiteShoppingInfoMobile({
  componentId,
  elements,
  type,
}) {
  elements.forEach((element) => {
    element.id = element.title_label;
  });
  return (
    <Section type={type}>
      <CarouselSnapped {...carouselProps} srLabel={elements[0].title_label}>
        { elements.map(elem => (
          <CarouselSnappedSlide key={`${componentId}-shopping-info-${elem.id}`}>
            <InfoSlide {...elem} />
          </CarouselSnappedSlide>
        ))}
      </CarouselSnapped>
    </Section>
  );
}

SiteShoppingInfoMobile.propTypes = {
  componentId: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title_label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

module.exports = SiteShoppingInfoMobile;
